<template>
  <div class="row no-gutters existing-sec">
    <div class="col-12 col-md-3">
      <h1 class="exist-heading">Existing Users</h1>
    </div>
    <div class="col-12 col-md-9 mt-3 mt-md-0">
      <TopSearch v-model="searchQuery" />
      <div class="table-container">
        <div>
          <table class="table">
            <thead>
              <tr class="header-row">
                <th v-for="header in tableHeaders" :key="header.name">
                  {{ header.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{ user.firstName }}</td>
                <td>{{ user.lastName }}</td>
                <td>{{ user.username }}</td>
                <td>{{ user.role }}</td>
                <td>{{ user.status }}</td>
                <td>
                  <img src="../../assets/images/table/edit.png" alt="Edit" @click="showModal(user)"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <EditUserModalComponent ref="editUserModal" :user="selectedUser" @userUpdated="handleUserUpdated"/>
    </div>
  </div>
</template>

<script>
import EditUserModalComponent from './EditUserModalComponent.vue';
import TopSearch from './TopSearchComponent.vue';

export default {
  components: { TopSearch, EditUserModalComponent },
  name: "UserTableComponent",
  props:{
    users: Array
  },
  data() {
    return {
      tableHeaders: [
        { name: "firstName", label: "First Name" },
        { name: "lastName", label: "Last Name" },
        { name: "username", label: "Email" }, // Changed to username based on response
        { name: "role", label: "Roles" }, // Changed to role based on response
        { name: "status", label: "Status" }, // Adjust accordingly if you have a status field
        { name: "actions", label: "Actions" },
      ],
      searchQuery: '',
      selectedUser: null,
    };
  },
  methods: {
    showModal(user) {
      this.selectedUser = user;
      this.$emit("userData", user);
      this.$refs.editUserModal.openModal();
    },
    handleUserUpdated(updatedUser) {
      // Find the user in the `users` array and update it with the new data
      const userIndex = this.users.findIndex(u => u.id === updatedUser.id);
      if (userIndex !== -1) {
        this.$set(this.users, userIndex, updatedUser);
      }
    }
  },
  mounted() {
  },
};
</script>


