<template>
  <div class="table-top-left">
    <div class="top-content p-0">
      <h6>Sample Composition Correction {{ sampleId }}</h6>
      <DropdownComponent @sample-selected="updateSampleId" />
    </div>
    <div class="data-strip view-summary-data-strip">
      <div>
        <div v-for="(field, index) in fields" :key="index" class="data-field">
          <div class="field-label">
            <span class="label">{{ field?.label }}</span>
            <span
              class="value"
              :style="field.label === ' ' ? aiCorrectedStyle : ''"
            >
              {{ field?.value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import DropdownComponent from './DropdownComponent.vue';

export default {
  components: { DropdownComponent },
  name: "ChartDataTopLeftComponent",
  props: {
    location: Object,
    sampleData: Object
  },
  data() {
    return {
      sampleId: this.sampleData?.name,
      fields: [
        { label: "Asset", value: this.location?.asset },
        { label: "Field", value: this.location?.field },
        { label: "Well", value: this.location?.well },
        { label: "Sample", value: this.sampleData?.name },
        { label: " ", value: this.sampleData?.correctionStatus ? "AI Corrected" : "Cannot be corrected" }
      ]
    };
  },
  computed: {
    aiCorrectedStyle() {
      return {
        color: this.sampleData?.correctionStatus ? '#FFCD00' : '#FD0000'
      };
    }
  },
  watch: {
    sampleData: {
      immediate: true,
      handler(newSampleData) {
        this.sampleId = newSampleData?.name || '';
        this.fields = [
          { label: "Asset", value: this.location?.asset },
          { label: "Field", value: this.location?.field },
          { label: "Well", value: this.location?.well },
          { label: "Sample", value: newSampleData?.name },
          { label: " ", value: newSampleData?.isCorrected ? "AI Corrected" : "Cannot be corrected" }
        ];
      }
    }
  },
  methods: {
    updateSampleId(newSampleId) {
      this.sampleId = newSampleId.sampleName;
      this.$emit('sample-updated', newSampleId.sampleID);
    }
  }
};
</script>

