<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#DeleteRole"
      ref="openModalDeleteRole"
    ></button>
    <div class="modal" tabindex="-1" role="dialog" id="DeleteRole">
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0 mr-0">
            <img
              src="../../assets/images/modal/delete-tranparent.png"
              alt="cross"
            />
            <h1>Delete Confirmation</h1>
            <div class="role-permission-sec">
              <h4>Role: <span>{{ role ? role.name : 'N/A' }}</span></h4>
              <h6>Permissions</h6>
              <div class="permission-sec">
                <span
                  v-for="perm in role ? role.permissions : []"
                  :key="perm"
                  :style="{ backgroundColor: getPermissionColor(perm) }"
                  class="permission-wrap mr-0"
                >
                  {{ perm }}
                </span>
              </div>
            </div>
            <p>
              Do you really want to delete this role? <br/>Permissions will be
              deleted along with it. This process <br/>cannot be undone.
            </p>
          </div>
          <div class="modal-footer p-0">
            <button type="button" class="btn cancel" data-dismiss="modal" @click="closeModal">
              Cancel
            </button>
            <button type="button" class="btn btn-main" @click="confirmDelete">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "DeleteRoleComponent",
  data() {
    return {
      role: null // Add a property to store the role
    };
  },
  methods: {
    getPermissionClass(permission) {
      return permission ? (permission === 'Read' ? 'bg-red' : (permission === 'Write' ? 'bg-green' : (permission === 'Execute' ? 'bg-blue' : ''))) : '';
    },
     getPermissionColor(permission) {
      switch (permission.toLowerCase()) {
        case "manageusers": return "#ff4c4c";
        case "manageroles": return "#28a745";
        case "managefilters": return "#007bff";
        case "managesamples": return "#ffc107";
        case "importsamples": return "#17a2b8";
        case "exportsamplestopvtintelligence": return "#6f42c1";
        case "guest": return "#6c757d";
        case "viewusers": return "#fd7e14";
        default: return "#6c757d";
      }
    },
    openModal(role) {
      this.role = role; // Set the role data
      var elem = this.$refs.openModalDeleteRole;
      if (elem) {
        elem.click();
      }
    },
    confirmDelete() {
      // Replace with your API call to delete the role
      axios.delete(`/api/roles/${this.role.id}`)
        .then(() => {
          this.$emit('roleDeleted', this.role.id); // Emit an event to notify the parent component
          this.closeModal(); // Optionally close the modal after deletion
        })
        .catch(error => {
          console.error("Error deleting role:", error);
        });
    },
    closeModal() {
      this.role = null; // Reset role data
    },
  },
};
</script>

<style scoped>
.bg-red { background-color: red; }
.bg-green { background-color: green; }
.bg-blue { background-color: blue; }
</style>
