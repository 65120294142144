<template>
  <div>
    <SuccessModalComponent ref="successModal" successMessage="User Added Successfully" />
    <div class="add-sec">
      <div class="container-fluid">
        <div class="row no-gutters">
          <div class="col-12 col-md-3 mb-3 mb-md-0">
            <h1>Add Users</h1>
          </div>
          <div class="col-12 col-md-9">
            <form>
              <div class="row">
                <div class="col-12 col-md-6">
                  <label for="email">User Email Address</label>
                  <input
                    v-model="email"
                    type="text"
                    class="form-control"
                    placeholder="Enter User Email"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3 mt-md-0 pl-md-0">
                  <label for="Roles">Select Roles</label>
                  <vue3-select
                  v-model="select_roles"
                    :options="roles"
                    placeholder="Select Roles"
                    :loading="isRolesLoading"
                    :disabled="isRolesLoading || roles.length === 0"
                    label="name"   
                    value="id"      
                  />
                </div>
              </div>
            </form>
            <div class="d-flex align-items-center justify-content-end">
              <button
                type="button"
                class="btn main-btn"
                :disabled="isLoading || !email || !select_roles"
                @click="addUser"
              >
                <span v-if="isLoading">Adding...</span>
                <div v-else>
                  <img
                    src="../../assets/images/userRoles/add-circle.png"
                    alt="Add"
                  />
                  <span>Add User</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuccessModalComponent from './SuccessModalComponent.vue';
import axios from 'axios';

export default {
  components: { SuccessModalComponent },
  name: "AddUserComponent",
  data() {
    return {
      email: null,
      select_roles: null,
      roles: [], // Initialize roles as an empty array
      isLoading: false,
      isRolesLoading: false, // Loader for fetching roles
    };
  },
  mounted() {
    this.fetchRoles(); // Fetch roles when the component is mounted
  },
  methods: {
    async fetchRoles() {
      this.isRolesLoading = true;
      try {
        const response = await axios.get('/roles'); // Make API call to get roles
        this.roles = response.data.data || []; // Update to use response.data.data
        console.log(this.roles )
      } catch (error) {
        console.error("Error fetching roles:", error.response || error.message);
        // Handle error appropriately (optional)
      } finally {
        this.isRolesLoading = false;
      }
    },
    async addUser() {
      // Prepare the user data
      const userData = {
        Data: {
          User: {
            role: this.select_roles.name,
            username: this.email,
          },
        },
      };

      this.isLoading = true;

      try {
        // Make the POST request to add the user
        const response = await axios.post('/users', userData);
        console.log(response.data); // Log response data (optional)

        // Show success modal on successful response
        this.$refs.successModal.openModal();

        // Reset the form fields
        this.email = null;
        this.select_roles = null;
      } catch (error) {
        console.error("Error adding user:", error.response || error.message);
        // Handle error appropriately
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
