<template>
  <div class="form-wrapper">
    <h6>Sign in to Compo++</h6>
    <button class="sign-in-btn" @click="redirectToSSO" :disabled="isLoading">
      <span v-if="isLoading" class="spinner">Signing in...</span>
      <span v-else>Sign in using AzureAD SSO</span>
    </button>
    <form @submit.prevent="loginWithCredentials">
      <div class="form-group">
        <label for="username">Username</label>
        <input
          type="text"
          class="form-control"
          id="username"
          v-model="username"
          placeholder="Enter your username"
          required
        />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="password"
          placeholder="Insert your password"
          required
        />
      </div>
      <div class="forgot-password">
        <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input checkbox"
              id="exampleCheck1"
            />
            <label class="form-check-label" for="exampleCheck1">
              Keep me logged in
            </label>
        </div>
        <!-- <router-link to="/forgot-password">Forgot Password?</router-link> -->
      </div>
      <button type="submit" class="btn main-btn" :disabled="isLoading">
        <span v-if="isLoading">Signing in...</span>
        <span v-else>Sign In</span>
      </button>
    </form>
    <!-- <div class="account">
        <span>Don't have an account? </span>
        <router-link to="/register">Register</router-link>
    </div> -->
    <LoginFooterComponent />

    <!-- Full-screen loader -->
    <div v-if="isLoading" class="loader-overlay">
    <LoaderComponent  />
    </div>
  </div>
</template>

<script>
import LoginFooterComponent from "./LoginFooterComponent.vue";
import LoaderComponent from "../Loader/CircularLoader.vue";
import { mapActions } from 'vuex';
import { showErrorToast } from "../../services/toastService";

export default {
  components: { LoginFooterComponent, LoaderComponent },
  name: "AuthFormComponent",
  data() {
    return {
      isLoading: false,
      username: '',
      password: '',
    };
  },
  methods: {
    ...mapActions(['login']),
    async redirectToSSO() {
      this.isLoading = true;
      try {
        const ssoUrl = process.env.VUE_APP_SSO_URL;
        console.log(ssoUrl)
        window.location.href = ssoUrl;
      } catch (error) {
        console.error('Error fetching SSO URL:', error);
      } finally {
        this.isLoading = true;
      }
    },
    async loginWithCredentials() {
      this.isLoading = true;
      try {
        const success = await this.login({
          username: this.username,
          password: this.password,
        });
        if (success) {
          this.$router.push({ path: '/' });
        } else {
          showErrorToast("Login failed, please check your credentials.");

        }
      } catch (error) {
        console.error('Login error:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>


