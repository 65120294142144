<template>
  <div class="chart-data-bottom">
    <div v-if="chartType === 'all'" class="form-check d-flex align-items-center">
      <input
        class="form-check-input mt-0 custom-checkbox"
        type="checkbox"
        id="flashedGas"
        @change="updateSampleData"
        v-model="filterData.flashed_gas"
      />
      <label class="form-check-label flashedGas" for="flashedGas"> Flashed Gas </label>
      <span class="marker" :class="shapeMapCorrected.flashed_gas"></span>
    </div>

    <div v-if="chartType === 'all' || chartType === 'sto'" class="form-group">
      <h6>Flashed Liquid</h6>
      <div class="form-check d-flex align-items-center">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="stoCorrectedComposition"
          @change="updateSampleData"
          v-model="filterData.sto_corrected_composition"
        />
        <label
          class="form-check-label stoCorrectedComposition"
          for="stoCorrectedComposition"
        >
          Corrected Composition
        </label>
        <span class="marker" :class="shapeMapCorrected.sto_corrected_composition"></span>
      </div>
      <div class="form-check d-flex align-items-center mt-2">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="stoUncorrectedComposition"
          @change="updateSampleData"
          v-model="filterData.sto_uncorrected_composition"
        />
        <label
          class="form-check-label stoUncorrectedComposition"
          for="stoUncorrectedComposition"
        >
          Uncorrected Composition
        </label>
        <span
          class="marker"
          :class="shapeMapUncorrected.sto_uncorrected_composition"
        ></span>
      </div>
    </div>

    <div v-if="chartType === 'all'" class="form-group">
      <h6>Separator Liquid</h6>
      <div class="form-check d-flex align-items-center">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="separatorLiquidCorrected"
          @change="updateSampleData"
          v-model="filterData.separator_liquid_corrected"
        />
        <label
          class="form-check-label separatorLiquidCorrected"
          for="separatorLiquidCorrected"
        >
          Corrected Composition
        </label>
        <span class="marker" :class="shapeMapCorrected.separator_liquid_corrected"></span>
      </div>
      <div class="form-check d-flex align-items-center mt-2">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="separatorLiquidUncorrected"
          @change="updateSampleData"
          v-model="filterData.separator_liquid_uncorrected"
        />
        <label
          class="form-check-label separatorLiquidUncorrected"
          for="separatorLiquidUncorrected"
        >
          Uncorrected Composition
        </label>
        <span
          class="marker"
          :class="shapeMapUncorrected.separator_liquid_uncorrected"
        ></span>
      </div>
    </div>

    <div v-if="chartType === 'all'" class="form-check d-flex align-items-center">
      <input
        class="form-check-input mt-0 custom-checkbox"
        type="checkbox"
        id="separatorGas"
        v-model="filterData.separator_gas"
        @change="updateSampleData"
      />
      <label class="form-check-label separatorGas" for="separatorGas">
        Separator Gas
      </label>
      <span class="marker" :class="shapeMapCorrected.separator_gas"></span>
    </div>

    <div v-if="chartType === 'all'" class="form-group">
      <h6>Reservoir Fluid</h6>
      <div class="form-check d-flex align-items-center">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="reservoirFluidCorrected"
          @change="updateSampleData"
          v-model="filterData.reservoir_fluid_corrected"
        />
        <label
          class="form-check-label reservoirFluidCorrected"
          for="reservoirFluidCorrected"
        >
          Corrected Composition
        </label>
        <span class="marker" :class="shapeMapCorrected.reservoir_fluid_corrected"></span>
      </div>
      <div class="form-check d-flex align-items-center mt-2">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="reservoirFluidUncorrected"
          @change="updateSampleData"
          v-model="filterData.reservoir_fluid_uncorrected"
        />
        <label
          class="form-check-label reservoirFluidUncorrected"
          for="reservoirFluidUncorrected"
        >
          Uncorrected Composition
        </label>
        <span
          class="marker"
          :class="shapeMapUncorrected.reservoir_fluid_uncorrected"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SampleChartBottomComponent",
  data() {
    return {
      filterData: {
        flashed_gas: true,
        sto_corrected_composition: true,
        sto_uncorrected_composition: true,
        separator_liquid_corrected: true,
        separator_liquid_uncorrected: true,
        separator_gas: true,
        reservoir_fluid_corrected: true,
        reservoir_fluid_uncorrected: true,
      },
      shapeMapCorrected: {
        flashed_gas: "shape-circle-solid",
        sto_corrected_composition: "shape-square-solid",
        separator_liquid_corrected: "shape-triangle-up-solid",
        separator_gas: "shape-diamond-solid",
        reservoir_fluid_corrected: "shape-cross-solid",
      },
      shapeMapUncorrected: {
        flashed_gas: "shape-circle-outline",
        sto_uncorrected_composition: "shape-square-outline",
        separator_liquid_uncorrected: "shape-triangle-up-outline",
        separator_gas: "shape-diamond-outline",
        reservoir_fluid_uncorrected: "shape-cross-outline",
      },
    };
  },
  props: {
    chartType: String,
    showSelection: Boolean,
    switchMole:Boolean,
  },
  methods: {
    updateSampleData() {
      this.$emit("updateDataOnCheck", this.filterData);
    },
  },
  watch: {
    switchMole: {
      immediate: true,
      handler(switchMole) {
        this.filterData= {
        flashed_gas: true,
        sto_corrected_composition: true,
        sto_uncorrected_composition: true,
        separator_liquid_corrected: true,
        separator_liquid_uncorrected: true,
        separator_gas: true,
        reservoir_fluid_corrected: true,
        reservoir_fluid_uncorrected: true,
      };
      },
    },
  },
};
</script>

<style scoped>
.marker {
  display: inline-block;
  width: 12px; /* Adjust size as needed */
  height: 12px; /* Adjust size as needed */
  margin-left: 5px;
}

/* Shape Styles */
.shape-circle-solid {
  background-color: #4dc671;
  border-radius: 50%;
}

.shape-circle-outline {
  border: 2px solid currentColor;
  border-radius: 50%;
  background: none;
}

.shape-square-solid {
  width: 12px;
  height: 12px;
  background-color: #2a64b4;
}

.shape-square-outline {
  width: 12px;
  height: 12px;
  border: 2px solid #76a715;
  background: none;
}

.shape-diamond-solid {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #c74af6;
}

.shape-diamond-outline {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid transparent;
  border-top: 2px solid currentColor;
}

.shape-triangle-up-solid {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #180ab7;
}

.shape-triangle-up-outline {
  border-bottom: 1px solid #c46cbe;
  position: relative;
}

.shape-triangle-up-outline::before {
  content: "";
  position: absolute;
  background: #c46cbe;
  left: 3px;
  top: 1px;
  width: 1px;
  height: 12px;
  transform: rotate(30deg);
}

.shape-triangle-up-outline::after {
  content: "";
  position: absolute;
  background: #c46cbe;
  right: 3px;
  top: 1px;
  width: 1px;
  height: 12px;
  transform: rotate(-30deg);
}

.shape-cross-solid {
  position: relative;
  width: 12px;
  height: 12px;
}

.shape-cross-solid:before,
.shape-cross-solid:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #38c7c5;
}

.shape-cross-solid:before {
  transform: rotate(90deg);
}

.shape-cross-outline {
  position: relative;
  width: 12px;
  height: 12px;
}

.shape-cross-outline:before,
.shape-cross-outline:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: none;
  border: 1px solid #bcbbd6;
}

.shape-cross-outline:before {
  transform: rotate(90deg);
}
</style>
