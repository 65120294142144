<template>
  <div>
    <div class="table-top-right mt-4 mt-md-0">
      <ToggleModalButtons
      :checkedPercentage="checkedPercentage"
      :checked="checked"
      @update:checkedPercentage="updateIsCheckedPercentage"
      @update:checked="updateIsChecked"
    />
      <button type="button" class="btn" @click="exportData" :disabled="loading">
        <span v-if="loading">
          <span>Exporting...</span>
        </span>
        <span v-else class="btn">
          <img :src="export_data" alt="export" />
          <span>Export</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import ToggleModalButtons from "./ToggleModalButtons.vue";
export default {
  components: { ToggleModalButtons },
  name: "ComparisonModalRightComponent",
  props: {
    isCheckedPercentage: Boolean,
    isChecked: Boolean,
    sampleData: Object,
  },
  data() {
    return {
      export_data: require("../../../assets/images/table/export.png"),
      line_chart: require("../../../assets/images/table/line-chart.png"),
      loading: false,
      checkedPercentage: this.isCheckedPercentage,
      checked: this.isChecked,
    };
  },
  computed: {
    unitKey() {
      return this.isCheckedPercentage ? "Mole %" : "wt %";
    },
  },
  methods: {
    updateIsCheckedPercentage(value) {
      this.$emit("update:isCheckedPercentage", value);
    },
    updateIsChecked(value) {
      this.$emit("update:isChecked", value);
    },
    async exportData() {
      this.loading = true;
      try {
        const csvData = this.convertToCSV(this.sampleData); // Use your table data
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'data_comparison.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error exporting data:', error);
      } finally {
        this.loading = false;
      }
    },
    convertToCSV(data) {
      if (!data.correctedComposition || !data.originalComposition) {
        console.error('Invalid data structure:', data);
        return '';
      }
      const unitKey = this.unitKey;

      // Header for the CSV
      const headers = [
        "Component",
        "MW (g/mol)",
        "Density (g/cc)",
        "Flashed Gas Uncorrected (" + unitKey + ")",
        "Flashed Gas Corrected (" + unitKey + ")",
        "Flashed Gas Delta %",
        "Flashed Liquid Uncorrected (" + unitKey + ")",
        "Flashed Liquid Corrected (" + unitKey + ")",
        "Flashed Liquid Delta %",
        "Overall Uncorrected (" + unitKey + ")",
        "Overall Corrected (" + unitKey + ")", 
        "Overall Delta %",
      ];

      // CSV rows
      const csvRows = [headers.join(',')];

      // Function to safely apply toFixed to numbers
      const safeToFixed = (value, decimals) => {
        if (value !== undefined && value !== null) {
          return Number(value).toFixed(decimals);
        }
        return "-"; // Or '0.0000' if you want to show the full precision
      };

     const calculateDelta = (corrected, uncorrected) => {
        if (
          corrected !== undefined &&
          corrected !== null &&
          uncorrected !== undefined &&
          uncorrected !== null &&
          uncorrected != 0
        ) {
          const delta = ((corrected - uncorrected) / uncorrected * 100).toFixed(1);
          return isNaN(delta) ? "-" : delta + " %";
        }
        return "-";
      };

      // Combine data from corrected and original compositions
      const combinedData = data.correctedComposition.composition.map(correctedItem => {
        const originalItem = data.originalComposition.composition.find(
          item => item.component === correctedItem.component
        );

        const flashedGasCorrected = this.isCheckedPercentage
          ? safeToFixed(correctedItem.stockTankGas_MoleFraction, 3)
          : safeToFixed(correctedItem.stockTankGas_MassFraction, 3);
        const flashedGasUncorrected = this.isCheckedPercentage
          ? safeToFixed(originalItem?.stockTankGas_MoleFraction, 3)
          : safeToFixed(originalItem?.stockTankGas_MassFraction, 3);
        const flashedGasDelta = calculateDelta(flashedGasCorrected, flashedGasUncorrected);

        const flashedLiquidCorrected = this.isCheckedPercentage
          ? safeToFixed(correctedItem.stockTankOil_MoleFraction, 3)
          : safeToFixed(correctedItem.stockTankOil_MassFraction, 3);
        const flashedLiquidUncorrected = this.isCheckedPercentage
          ? safeToFixed(originalItem?.stockTankOil_MoleFraction, 3)
          : safeToFixed(originalItem?.stockTankOil_MassFraction, 3);
        const flashedLiquidDelta = calculateDelta(flashedLiquidCorrected, flashedLiquidUncorrected);

        const overallCorrected = this.isCheckedPercentage
          ? safeToFixed(correctedItem.reservoirLiquid_MoleFraction, 3)
          : safeToFixed(correctedItem.reservoirLiquid_MassFraction, 3);
        const overallUncorrected = this.isCheckedPercentage
          ? safeToFixed(originalItem?.reservoirLiquid_MoleFraction, 3)
          : safeToFixed(originalItem?.reservoirLiquid_MassFraction, 3);
        const overallDelta = calculateDelta(overallCorrected, overallUncorrected);

        return {
          component: correctedItem.component || "-",
          mw: safeToFixed(correctedItem.mw, 1),
          density: safeToFixed(correctedItem.density, 3),
          flashedGas: {
            corrected: flashedGasCorrected || "-",
            uncorrected: flashedGasUncorrected || "-",
            delta: flashedGasDelta
          },
          flashedLiquid: {
            corrected: flashedLiquidCorrected || "-",
            uncorrected: flashedLiquidUncorrected || "-",
            delta: flashedLiquidDelta
          },
          overall: {
            corrected: overallCorrected || "-",
            uncorrected: overallUncorrected || "-",
            delta: overallDelta
          }
        };
      });
 
      // Convert each row into CSV format
      combinedData.forEach(row => {
        const csvRow = [
          `"${row.component}"`,
          `"${row.mw}"`,
          `"${row.density}"`,
          `"${row.flashedGas.uncorrected}"`,
          `"${row.flashedGas.corrected}"`,
          `"${row.flashedGas.delta}"`,
          `"${row.flashedLiquid.uncorrected}"`,
          `"${row.flashedLiquid.corrected}"`,
          `"${row.flashedLiquid.delta}"`,
          `"${row.overall.uncorrected}"`,
          `"${row.overall.corrected}"`,
          `"${row.overall.delta}"`,
        ];
        csvRows.push(csvRow.join(','));
      });

      return csvRows.join('\n');
    },
  },
};
</script>
