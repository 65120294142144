<template>
  <div class="view-top-wrapper">
    <div class="row no-gutters align-items-center top-content">
      <div class="col-12 col-lg-4 col-xl-6">
        <ChartDataTopLeftComponent
          :location="sampleData?.location"
          :sampleData="sampleData"
          @sample-updated="fetchNewSampleList"
        />
      </div>
      <div class="col-12 col-lg-8 col-xl-6 py-3 py-lg-0">
        <ChartDataTopRightComponent
          :isCorrected="sampleData?.isCorrected"
          :view="currentView"
          @viewChanged="handleViewChange"
          @switchMoleUpdate="switchMoleUpdate"
          @yAxisScaleUpdate="yAxisScaleUpdate"
          @onToggleChange="onToggleChange"
          :location="sampleData?.location"
          :sampleData="sampleData"
        />
      </div>
    </div>
    <div class="row no-gutters view-chart-wrapper">
      <div class="col px-5" v-if="isChart">
        <ScatterChart
          :localSampleList="localSampleList"
          :originalSampleList="originalSampleList"
          :yAxisScale="yAxisScale"
          :switchMole="switchMole"
        />
        <SampleChartBottomComponent
          @updateDataOnCheck="updateFilteredData"
          :switchMole="switchMole"
          :chartType="chartType"
        />
      </div>
      <div class="col" v-if="isTable">
        <TableData
          :location="sampleData?.location"
          :useCorrectedData="useCorrectedData"
          :sampleData="sampleData"
          @sample-updated="fetchNewSampleList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChartDataTopLeftComponent from "./ChartDataTopLeftComponent.vue";
import TableData from "./ShowData/TableDataComponent.vue";
import SampleChartBottomComponent from "./SampleChartBottomComponent.vue";
import ChartDataTopRightComponent from "./ChartDataTopRightComponent.vue";
import ScatterChart from "@/components/Charts/ScatterChart.vue";
import { getFilteredData } from "@/utils/useFilteredData.js";

export default {
  name: "SampleChartDataComponent",
  components: {
    ChartDataTopLeftComponent,
    TableData,
    SampleChartBottomComponent,
    ChartDataTopRightComponent,
    ScatterChart,
  },
  props: {
    sampleData: Object,
  },
  data() {
    return {
      chartType: "all",
      currentView: "chart",
      originalSampleList: null,
      localSampleList: null,
      yAxisScale: true,
      switchMole: true,
      useCorrectedData: true,
      filterCriteria: {
        flashed_gas: true,
        sto_corrected_composition: true,
        sto_uncorrected_composition: true,
        separator_liquid_corrected: true,
        separator_liquid_uncorrected: true,
        separator_gas: true,
        reservoir_fluid_corrected: true,
        reservoir_fluid_uncorrected: true,
      }, // Add this to handle your filter criteria
    };
  },
  computed: {
    isChart() {
      return this.currentView === "chart";
    },
    isTable() {
      return this.currentView === "table";
    },
  },
  mounted() {},
  methods: {
    handleViewChange(view) {
      this.currentView = view;
      localStorage.setItem("currentView", view);
    },
    fetchNewSampleList(newSampleId) {
      this.$emit("update-sample", newSampleId);
    },
    switchMoleUpdate(switchMole) {
      this.switchMole = switchMole;
      this.updateFilteredData(this.filterCriteria);
    },
    yAxisScaleUpdate(yAxisScale) {
      this.yAxisScale = yAxisScale;
    },
    onToggleChange(isChecked) {
      this.useCorrectedData = !isChecked;
    },
    async updateFilteredData(filterCriteria) {
      this.localSampleList = await getFilteredData(
        filterCriteria,
        this.originalSampleList,
        this.sampleData,
        this.switchMole
      );
    },
  },
  watch: {
    sampleData: {
      immediate: true,
      handler(newSampleList) {
        this.originalSampleList = newSampleList;
        this.localSampleList = newSampleList;
        this.updateFilteredData(this.filterCriteria);
      },
    },
  },
};
</script>
