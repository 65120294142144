<template>
  <div class="main-page">
    <div class="navbar-wrapper">
      <NavbarComponent />
    </div>
    <div class="container-fluid main-wrapper view-summary-wrapper">
      <div class="row no-gutters">
        <div class="col-12">
          <div v-if="loading" class="loader-overlay">
            <CircularLoader />
          </div>
          <div v-else-if="sampleData">
            <SampleChartDataComponent
              :sampleData="sampleData"
              @update-sample="fetchNewSampleData"
            />
          </div>
          <div v-else>
            <p class="error-message">No data available.</p>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import axios from "axios";
import NavbarComponent from "../components/Layout/NavbarComponent.vue";
import SampleChartDataComponent from "../components/ViewSummary/SampleChartDataComponent.vue";
import FooterComponent from "@/components/Layout/FooterComponent.vue";
import CircularLoader from "../components/Loader/CircularLoader.vue";

export default {
  name: "ViewSummary",
  components: {
    NavbarComponent,
    SampleChartDataComponent,
    FooterComponent,
    CircularLoader,
  },
  data() {
    return {
      sampleData: null,
      loading: false,
      error: null,
    };
  },
  async created() {
    const id = this.$route.params.id;
    if (!id) {
      this.error = "Invalid ID.";
      return;
    }
    this.loading = true;
    await this.fetchSampleData(id);
  },
  methods: {
    async fetchSampleData(id) {
      this.loading = true;
      this.error = null; // Clear any previous errors
      try {
        const response = await axios.get(`samples/${id}`);
        if (response.data && response.data.data) {
          this.sampleData = response.data.data;
        } else {
          throw new Error("Data not found");
        }
      } catch (error) {
        console.error("Error loading sample data", error);

        if (error.response && error.response.status === 404) {
          this.error = "Sample not found.";
          this.$router.push("/404");
        } else if (error.code === "ERR_NETWORK" || error.code === 401) {
          alert("Network error. Please try again.");
          setTimeout(() => {
            this.$router.push("/404");
          }, 1000);
        } else {
          this.error = "An unknown error occurred.";
        }
      } finally {
        this.loading = false;
      }
    },

    async fetchNewSampleData(newSampleId) {
      this.loading = true;
      await this.fetchSampleData(newSampleId);
    },

    retryFetch() {
      const id = this.$route.params.id;
      this.fetchSampleData(id);
    },
  },
  beforeUnmount() {
    // Handle any necessary cleanup here
  },
};
</script>

<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}
</style>
