<template>
  <div class="row">
    <div class="col-12 col-md-6 mb-3 mb-md-0">
      <div class="search-container">
        <button class="search-btn">
          <img src="../../assets/images/userRoles/search.png" alt="search" />
        </button>
        <input
          v-model="search"
          type="text"
          placeholder="Search with User"
          class="search-input"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 pl-md-0">
      <vue3-select
        v-model="select_roles"
        :options="roles"
        placeholder="Search with Roles"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TopSearch",
  data() {
    return {
      search: null,
      select_roles: null,
      roles: ["Admin", "Engineer", "Manager"],
    }
  }
};
</script>