<template>
   <div class="dashboard-chart-selection my-3">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12">
          <label>Select a Treemap/Sunburst</label>
          <vue3-select
            v-model="property"
            :options="options"
            label="value"      
            :reduce="option => option"
             @update:modelValue="fetchtreemapChart()"
            :close-on-select="true"
            placeholder="Select Options"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-charts">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-xl-12 chart-container">
          <div class="text-center error-message erro-2" v-if="chartError">{{ chartError }}</div>
          <free-style-shimmer
            v-if="loading"
            :is-loading="true"
            height="780px"
            width="100%"
            border-radius="12px"
            color="#F6F6F6"
          />
          <iframe
          v-else-if="!loading"
          :srcdoc="treemapChart"
          style="
            width: 100%;
            height: 780px;
            border: 0;
            overflow: hidden;
          "
            scrolling="no"
        ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DashboardTreeMapComponent",
  data() {
    return {
      loading: true, 
      treemapChart: null, 
      chartError: null,
      property: { name: '"c36plus_Density"', value: "C36+ Density" },
      options: [
        { name: '"c36plus_Density"', value: "C36+ Density" },
        { name: '"c36plus_MW"', value: "C36+ MW" },
        { name: '"sto_Density"', value: "STO Density" },
        { name: '"c36plus_MassFraction"', value: "C36+ Mass Fraction" },
        { name: '"sto_MW"', value: "STO MW" },
      ]
    };
  },
   methods: {
    async fetchtreemapChart() {
      this.loading=true;
      this.chartError=null;
      await axios.post(`dataapi/dashboard/treemap`, 
          {
            property: this.property?.value
          }, 
          {
            params: {
              property:this.property?.name
            }
          }
        ) 
        .then((response) => {
          if (response.data && response.data.data) {
            this.treemapChart = response.data.data.response;
          } else {
            this.chartError = "Data not found";
          }
          this.loading = false; 
        })
        .catch((error) => {
          console.error("Error fetching chart data:", error);
          this.chartError="Error fetching chart data.";
          this.loading = false;
          this.treemapChart = null;
        });
    },
  },
  mounted() {
    this.fetchtreemapChart(); 
  },
};
</script>

<style>
</style>