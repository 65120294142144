<template>
  <div class="main-page">
    <div class="navbar-wrapper">
      <NavbarComponent />
    </div>
    <div class="container-fluid main-wrapper">
      <div class="row no-gutters">
        <div class="col-12 col-lg-8 col-xl-8">
          <DashboardLeftTopComponent />
          <DashboardTreeMapComponent />
        </div>
        <div class="col-12 col-lg-4 col-xl-4 pl-lg-3 mt-3 mt-lg-0">
          <DashboardInventoryAnalysisComponent />
          <DashboardRightChartsBotoomComponent />
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from "../components/Layout/NavbarComponent.vue";
import FooterComponent from "@/components/Layout/FooterComponent.vue";
import DashboardLeftTopComponent from "@/components/Dashboard/DashboardLeft/DashboardLeftTopComponent.vue";
import DashboardTreeMapComponent from "@/components/Dashboard/DashboardLeft/DashboardTreeMapComponent.vue";
import DashboardInventoryAnalysisComponent from "@/components/Dashboard/DashboardRight/DashboardInventoryAnalysisComponent.vue";
import TwoDHistogram from "@/components/Charts/2DHistogram.vue";
import DashboardRightChartsBotoomComponent from '@/components/Dashboard/DashboardRight/DashboardRightChartsBotoomComponent.vue';

export default {
  name: "SampleSummary",
  components: {
    NavbarComponent,
    FooterComponent,
    DashboardLeftTopComponent,
    DashboardTreeMapComponent,
    DashboardInventoryAnalysisComponent,
    TwoDHistogram,
    DashboardRightChartsBotoomComponent,
  },
};
</script>
