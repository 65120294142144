<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
    <span class="navbar-brand">
      <img :src="logo" alt="logo" />
    </span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link to="/dashboard" exact class="nav-link">
            <div class="img-wrap-first">
              <img :src="task" alt="dashboard" />
            </div>
            <span>Dashboard</span>
          </router-link>
          <!-- <div class="img-wrap-last">
            <img :src="arrow_down" alt="arrow_down" />
          </div> -->
        </li>
        <li class="nav-item">
          <router-link to="/" exact class="nav-link">
            <div class="img-wrap-first">
              <img :src="category" alt="category" />
            </div>
            <span>Compositions Correction</span>
          </router-link>
          <!-- <div class="img-wrap-last">
            <img :src="arrow_down" alt="arrow_down" />
          </div> -->
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item">
          <div class="img-wrap-user">
            <img :src="user_image" alt="img" />
          </div>
          <div>
            <div class="username">{{ userData?.firstName }} {{ userData?.lastName }}</div>
            <div class="designation">{{ userData?.role }}</div>
          </div>
        </li>
        <div class="dropdown">
          <button class="border-0 img-wrap-last" type="button" data-toggle="dropdown" data-display="static"
            aria-expanded="false">
            <img :src="arrow_down" alt="arrow_down" />
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <router-link to="/manage-users" exact class="dropdown-item" v-if="hasPermission('ManageUsers')">
              <span>Users & Roles</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item disabled" v-if="isLoggingOut">Logging out...</a>
            <a class="dropdown-item" @click="handleLogout" :disabled="isLoggingOut" v-else>Logout</a>
          </div>
        </div>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions, mapState, } from 'vuex';
import defaultUserImage from "../../assets/images/navbar/user.png";
export default {
  name: "NavbarComponent",
  data() {
    return {
      logo: require("../../assets/images/navbar/logo.png"),
      task: require("../../assets/images/navbar/task.png"),
      arrow_down: require("../../assets/images/navbar/arrow-down.png"),
      category: require("../../assets/images/navbar/category.png"),
    };
  },
  methods: {
    ...mapActions(['logout']),
    handleLogout() {
      this.logout();
    }
  },
  computed: {
    ...mapState({
      isLoggingOut: state => state.auth.isLoggingOut, // Get loader state
      username: state => state.auth.user ? state.auth.user.username : '' // Assuming user data is stored in auth state
    }),
    ...mapGetters({
      userData: 'user'
    }),
    ...mapGetters(['hasPermission']),
    user_image() {
      const imageURL = this.userData?.imageUrl;
      const baseUrl = process.env.VUE_APP_PROFILE_URL;
      return imageURL ? `${baseUrl}${imageURL}` : defaultUserImage;
    },
  },
};
</script>