<template>
  <div class="toggle-select-wrapper">
    <div class="toggle-container">
      <span class="toggle-label">AI Corrected Data</span>
      <label class="toggle">
        <input type="checkbox" :disabled="!this.sampleData?.correctionStatus" v-model="isChecked" @change="toggleData" />
        <span class="slider round"></span>
      </label>
      <span class="toggle-label">Uncorrected Data</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleDataComponent",
  data() {
    return {
      isChecked: false
    };
  },
  props: {
    sampleData: Object,
  },
  methods: {
    toggleData() {
      this.$emit('toggle-change', this.isChecked);
      this.$emit('toggle-change', this.isChecked);
    }
  },
  mounted() {
    if(this.sampleData?.correctionStatus==false)
      {
        this.isChecked=true;
        this.$emit('toggle-change', this.isChecked);
      }
  },
  watch: {
    sampleData(newVal) {
      if(newVal?.correctionStatus==false)
      {
        this.isChecked=true;
        this.$emit('toggle-change', this.isChecked);
      }
    },
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
