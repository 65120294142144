<template>
  <div>
    <RoleSuccessModalComponent
      ref="successModalRole"
      successMessage="Role Added Successfully"
    />

    <div class="add-sec">
      <div class="container-fluid">
        <div class="row no-gutters">
          <div class="col-12 col-md-3 mb-3 mb-md-0">
            <h1>Add Role</h1>
          </div>
          <div class="col-12 col-md-9">
            <form>
              <div class="row">
                <div class="col-12 col-md-6">
                  <label for="role-title">Role Title</label>
                  <input
                    v-model="role_title"
                    type="text"
                    class="form-control"
                    placeholder="Enter Role"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3 mt-md-0 pl-md-0">
                  <label for="permissions">Permissions</label>
                  <vue3-select
                    v-model="select_permission"
                    :options="permissions"
                    multiple
                    placeholder="Select Permissions"
                  />
                </div>
              </div>
            </form>
            <div class="d-flex align-items-center justify-content-end">
              <button
                type="button"
                class="btn main-btn"
                :disabled="isLoading || !role_title || !select_permission.length"
                @click="addRole"
              >
                <span v-if="isLoading">Adding...</span>
                <div v-else>
                  <img
                    src="../../assets/images/userRoles/add-circle.png"
                    alt="Add Role"
                  />
                  <span>Add Role</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import RoleSuccessModalComponent from "./RoleSuccessModalComponent.vue";

export default {
  name: "AddRoleComponent",
  components: { RoleSuccessModalComponent },
  data() {
    return {
      role_title: null,
      select_permission: [],
      permissions: [
        "Manage Users",
        "Manage Roles",
        "Manage Filter",
        "Manage Samples",
        "Import Samples",
        "Export Samples to PVT Intelligence",
      ],
      isLoading: false,
    };
  },
  methods: {
    // Function to convert permission string to camel case
    toCamelCase(str) {
      return str
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
          index === 0 ? match.toLowerCase() : match.toUpperCase()
        )
        .replace(/\s+/g, '');
    },

    async addRole() {
      if (!this.role_title || !this.select_permission.length) {
        return;
      }

      this.isLoading = true;

      // Convert selected permissions to camel case
      const camelCasePermissions = this.select_permission.map(permission =>
        this.toCamelCase(permission)
      );

      const payload = {
        Data: {
          Role: {
            name: this.role_title,
            description: "First Description", // Add a dynamic description if required
            permissions: camelCasePermissions,
          },
        },
      };

      try {
        const response = await axios.post("/roles", payload);
        console.log("Role created successfully:", response.data);

        // Show success modal
        this.$refs.successModalRole.openModal();

        // Reset the form after successful creation
        this.role_title = null;
        this.select_permission = [];
      } catch (error) {
        console.error("Error creating role:", error.response?.data || error.message);
        alert("Failed to create role.");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Add any required styling here */
</style>
