<template>
  <div class="import-sample mt-4 mt-lg-0">
    <h6 v-if="samples.length !== 0">Select samples to import...</h6>
    <div class="card border-0">
      <div class="card-body p-0">
        <div class="sample-list" ref="sampleList" v-if="samples.length > 0">
          <div
            class="sample-item"
            v-for="sample in samples"
            :key="sample.id"
          >
            <input
              type="checkbox"
              name="sample"
              class="radio"
              :value="sample"
              v-model="selectedSamples"
            />
            <div class="content">
              <p class="mb-0">
                Asset <strong>{{ sample?.location.asset }}</strong> | Field
                <strong>{{ sample?.location.field }}</strong> | Well
                <strong>{{ sample?.location.well }}</strong> | Sample
                <strong>#{{ sample?.name }}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="error-message" v-if="samples.length === 0">
          No samples found. Please try again.
        </div>
        <br />
        <div class="table-top-right justify-content-start mt-2" v-if="samples.length !== 0">
          <button class="btn btn-primary" @click="importData" :disabled="selectedSamples.length === 0">
            <span v-if="btnLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-else>Begin Import</span>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ImportSample",
  props: {
    requestID: String,
    selectedFile: Object,
    asset: String,
  },
  data() {
    return {
      samples: [],
      selectedSamples: [],
      btnLoading: false,
    };
  },
  watch: {
    requestID(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadSamples();
      }
    },
    selectedFile(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.uploadSampleFile();
      }
    }
  },
  methods: {
    async uploadSampleFile() {
      this.$emit('start-loading');
      try {
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        formData.append('asset', this.asset);

        const response = await axios.post('/samples/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.samples = response.data.data;
      } catch (error) {
        console.error("Error importing sample file:", error);
        this.$emit('close-modal');
      } finally {
        this.$emit('stop-loading');
      }
    },
    async loadSamples() {
      this.$emit('start-loading');
      try {
        const response = await axios.post(`/samples/importFromPVTIntelligence?requestID=${this.requestID}`);
        this.samples = response.data.data;
      } catch (error) {
        this.$emit('stop-loading');
        console.error("Error importing samples:", error);
        this.$emit('close-modal');
      } finally {
        this.$emit('stop-loading');
      }
    },
    async importData() {
      this.btnLoading = true;
      try {
        // Create an array of API call promises for each selected sample
        const importPromises = this.selectedSamples.map(sample => {
          const sampleData = {
            Data: {
              sample: sample
            }
          };
          return axios.post(`/samples`, sampleData);
        });

        // Wait for all API calls to complete
        const responses = await Promise.all(importPromises);

        // Check if all imports were successful
        const allSuccessful = responses.every(response => response.data.statusCode === 201);

        if (allSuccessful) {
          // Emit success modal if all imports were successful
          if(this.selectedSamples?.length==1 && this.selectedSamples[0]?.correctionStatus==false)
          {
            this.$emit(
              "show-dynamic-modal",
              this.selectedSamples,
              null, // No need for a specific ID for each
              "success",
              "",
              "All Imports Successful!",
              "highlighted",
              [
                { text: "Finish", class: "btn-secondary", action: "finish" },
              ]
            );
          }
          else
          {
              this.$emit(
              "show-dynamic-modal",
              this.selectedSamples,
              null, // No need for a specific ID for each
              "success",
              "",
              "All Imports Successful!",
              "highlighted",
              [
                { text: "Finish", class: "btn-secondary", action: "finish" },
                { text: "AI Correction", class: "btn-warning", action: "aiCorrection" },
              ]
            );
          }
         
        } else {
          // Emit error modal if any import failed
          this.$emit(
            "show-dynamic-modal",
            [],
            0,
            "error",
            "",
            "Error Loading File: One or more imports failed.",
            "normal",
            [{ text: "Ok", class: "btn-warning", action: "closeModal" }]
          );
        }

      } catch (error) {
        // Handle any error during the API calls
        this.$emit(
          "show-dynamic-modal",
          [],
          0,
          "error",
          "",
          "Error Loading File: Network or server error.",
          "normal",
          [{ text: "Ok", class: "btn-warning", action: "closeModal" }]
        );
        console.error("Error loading samples:", error);
      } finally {
        // Always stop the loading spinner
        this.btnLoading = false;
      }
    }
  },
};
</script>

<style scoped>
.sample-list {
  max-height: 450px;
  overflow-y: auto;
}

.loading-indicator {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: gray;
}
</style>
