<template>
  <div>
    <BarChartExpandComponent ref="barChartExpand" />
    
    <!-- Check if chartData is available -->
    <div v-if="chartData && chartData.trace1Data && chartData.trace2Data">
      <div ref="barChart" style="width: 100%;"></div>
      <button type="button" class="btn expand-btn" @click="showModal">
        <img :src="expand" alt="expand" />
        <span>Expand</span>
      </button>
    </div>
    
    <!-- Show "No chart available" message if no data -->
    <div v-else class="text-center mt-3">
      <p>No chart available.</p>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";
import BarChartExpandComponent from "./BarChartExpandComponent.vue";

export default {
  components: { BarChartExpandComponent },
  name: "BarChartComponent",
  props: {
    chartData: { type: Object, default: () => null }, // Default null to avoid errors
  },
   data() {
    return {
      expand: require("../../assets/images/table/expand.png"),
    };
  },
  methods: {
    showModal() {
      this.$refs.barChartExpand.openModal();
    },
    drawChart() {
      // Check if chartData exists
      if (!this.chartData || !this.chartData.trace1Data || !this.chartData.trace2Data) {
        return; // Do nothing if chartData is not available
      }

      const { trace1Data, trace2Data } = this.chartData;

      const trace1 = {
        x: ["giraffes", "orangutans", "monkeys"],
        y: trace1Data,
        name: "SF Zoo",
        type: "bar",
      };

      const trace2 = {
        x: ["giraffes", "orangutans", "monkeys"],
        y: trace2Data,
        name: "LA Zoo",
        type: "bar",
      };

      const data = [trace1, trace2];

      const layout = { barmode: "group" };

      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
      };

      Plotly.newPlot(this.$refs.barChart, data, layout, config);
    },
  },
  watch: {
    chartData: {
      handler() {
        this.drawChart(); // Call drawChart when chartData changes
      },
      immediate: true, // Call immediately when the component is mounted
    },
  },
};
</script>
