<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#barChart"
      ref="openModalBarExpand"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="barChart">
      <div
        class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
      >
        <div class="modal-content">
          <div class="modal-header flex-row">
            <h5 class="modal-title">Bar Chart</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <div ref="barChart" style="width: 100%; height: 500px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  name: "TreemapExpandComponent",
  methods: {
    openModal() {
      var elem = this.$refs.openModalBarExpand;
      if (elem) {
        elem.click();
      }
      this.$nextTick(() => {
        this.drawChart(); // Draw the chart when modal opens
      });
    },
    drawChart() {
      var trace1 = {
        x: ["giraffes", "orangutans", "monkeys"],
        y: [20, 14, 23],
        name: "SF Zoo",
        type: "bar",
      };

      var trace2 = {
        x: ["giraffes", "orangutans", "monkeys"],
        y: [12, 18, 29],
        name: "LA Zoo",
        type: "bar",
      };

      var data = [trace1, trace2];

      var layout = { barmode: "group" };

      Plotly.newPlot(this.$refs.barChart, data, layout);
    },
  },
};
</script>
