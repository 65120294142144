<template>
  <div class="table-container">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr class="header-row">
            <th v-for="header in tableHeaders" :key="header.name">
              {{ header.label }}
              <span @click="sortTable(header.name)">
                <span  v-if="sortColumn === header.name && sortDirection === 'asc'">
                  <i class="fa-solid fa-arrow-up ml-1"></i>
                </span>
                <span v-if="sortColumn === header.name && sortDirection === 'desc'">
                  <i class="fa-solid fa-arrow-down ml-1"></i>
                </span>
                <span v-if="sortColumn !== header.name">
                  <i class="fa-solid fa-arrow-up mx-1"></i>
                  <i class="fa-solid fa-arrow-down"></i>
                </span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tableData" :key="item.sampleID">
            <td>{{ item.sampleID }}</td>
            <td>{{ item.asset }}</td>
            <td>{{ item.field }}</td>
            <td>{{ item.well }}</td>
            <td>{{ item.reservoir }}</td>
            <td>{{ item.sampleType }}</td>
            <td>{{ item.containerId }}</td>
            <td>{{ item.sampleName }}</td>
            <td>
              <div v-if="item.correctionStatus==false">❌</div>
              <div v-if="item.isCorrected" class="tick-table">✔</div>
              
            </td>
            <td>-</td>
            <td>
              <div v-if="item.isAnchorPoint" class="tick-table">✔</div>
            </td>
            <td>
              <button class="ai-correction-button" @click="handleViewSummary(item.sampleID)">
                <img
                :src="view"
                alt="view"
                
              />
                <span>
                  AI Correction
                </span>
              </button>
              
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination-container">
      <div class="pagination-info">
        <select 
          v-model="itemsPerPage" 
          @change="changeItemsPerPage"
          @focus="showOverlay = true"
          @blur="showOverlay = false">
          <option
            v-for="option in itemsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        Items per page
      </div>
      <div class="items-per-page">
        {{ (pagination.currentPage - 1) * itemsPerPage + 1 }} -
        {{
          Math.min(pagination.currentPage * itemsPerPage, pagination.totalItems)
        }}
        of {{ pagination.totalItems }} items
      </div>
      <div class="page-info">
        <span class="d-flex align-items-center">
          <span class="pagination-info">
            <select 
              v-model="selectedPage" 
              @change="changePage(selectedPage)"
              @focus="handleFocus"
              @blur="handleBlur"
              ref="pageSelect"
            >
              <option v-for="page in pagination.totalPages" :key="page" :value="page">
                {{ page }}
              </option>
            </select>
          </span>
          of {{ pagination.totalPages }} pages</span
        >
        <nav>
          <ul class="pagination justify-content-center">
            <li
              class="page-item"
              :class="{ disabled: pagination.currentPage === 1 }"
            >
              <a
                class="page-link"
                href="#"
                @click.prevent="changePage(pagination.currentPage - 1)"
              >
                <img :src="left" alt="left-arrow" />
              </a>
            </li>
            <li
              class="page-item"
              :class="{
                disabled: pagination.currentPage === pagination.totalPages,
              }"
            >
              <a
                class="page-link"
                href="#"
                @click.prevent="changePage(pagination.currentPage + 1)"
              >
                <img :src="right" alt="right-arrow" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div v-if="showOverlay" class="overlay"  @click="hideOverlay"></div>
  </div>
</template>

<script>
export default {
  name: "TableDataComponent",
  props: {
    tableData: Array,
    pagination: Object,
    sortDirection: String,
    sortColumn:String
  },
  data() {
    return {
      view: require("../../assets/images/table/trend-up.png"),
      left: require("../../assets/images/table/left.png"),
      right: require("../../assets/images/table/right.png"),
      sortAsc: require("../../assets/images/table/eye.png"),
      sortDesc: require("../../assets/images/table/eye.png"),
      sortDefault: require("../../assets/images/table/eye.png"),
      tableHeaders: [
        { name: 'sampleID', label: 'ID Number' },
        { name: 'asset', label: 'Asset' },
        { name: 'field', label: 'Field' },
        { name: 'well', label: 'Well' },
        { name: 'reservoir', label: 'Reservoir' },
        { name: 'sampleType', label: 'Sample Type' },
        { name: 'containerID', label: 'Container Id' },
        { name: 'sampleName', label: 'Sample' },
        { name: 'isCorrected', label: 'AI Corrected' },
        { name: 'sampleDate', label: 'Import Date' },
        { name: 'isAnchorPoint', label: 'Anchor Point Data' },
        { name: 'view', label: 'View' },
      ],
      newSortColumn: '',
      newSortDirection: '',
      itemsPerPage: this.pagination.itemsPerPage,
      itemsPerPageOptions: [10, 20, 30, 40],
      selectedPage: this.pagination.currentPage || 1,
      showOverlay: false,
    };
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.pagination.totalPages) {
        this.$refs.pageSelect?.blur();
        this.$emit("changePage", page);
        this.handleBlur();
      }
    },
    changeItemsPerPage() {
      this.$emit("changeItemsPerPage", this.itemsPerPage);
    },
    handleViewSummary(id) {
      this.$router.push(`/summary/view/${id}`);
    },
    sortTable(column) {
      if (this.newSortColumn === column) {
        this.newSortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.newSortColumn = column;
        this.newSortDirection = 'asc';
      }
      this.$emit('sortTable', { column: this.newSortColumn, direction: this.newSortDirection });
    },
    handleFocus() {
      this.showOverlay = true; // Show overlay when select is focused
    },
    handleBlur() {
      setTimeout(() => {
        if (!this.$refs.pageSelect.contains(document.activeElement)) {
          this.hideOverlay();
        }
      }, 100);
    },
    hideOverlay() {
      this.showOverlay = false;
    }
  },
  watch: {
    "pagination.currentPage"(newPage) {
      this.selectedPage = newPage;
    },
  },
};
</script>
