<template>
  <div>
    <DeleteRoleComponent 
      ref="deleteRoleModal" 
      @roleDeleted="deleteRole" 
    />
    <EditRoleModalComponent ref="editRoleModal" @updateRole="updateRole" />
    <NewRoleModalComponent ref="newRoleModal" />
    <div class="row no-gutters existing-sec">
      <div class="col-12 col-md-3">
        <h1 class="exist-heading">Existing Roles</h1>
      </div>
      <div class="col-12 col-md-9 mt-3 mt-md-0">
        <TopSearch />
        <div class="table-container">
          <div>
            <table class="table">
              <thead>
                <tr class="header-row">
                  <th v-for="header in tableHeaders" :key="header.name">
                    {{ header.label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(role, index) in roles" :key="index">
                  <td>{{ role.name }}</td>
                  <td class="text-left">
                    <span
                      v-for="permission in role.permissions"
                      :key="permission"
                      :style="{ backgroundColor: getPermissionColor(permission) }"
                      class="permission-wrap"
                    >
                      {{ permission }}
                    </span>
                  </td>
                  <td>{{ role.description }}</td>
                  <td class="role-action">
                    <img 
                      src="../../assets/images/table/edit.png" 
                      alt="Edit" 
                      @click="showEditModal(role)" 
                    />
                    <img 
                      src="../../assets/images/table/trash.png" 
                      alt="Delete" 
                      @click="showModal(role)" 
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteRoleComponent from "./DeleteRoleComponent.vue";
import EditRoleModalComponent from './EditRoleModalComponent.vue';
import NewRoleModalComponent from './NewRoleModalComponent.vue';
import TopSearch from './TopSearchComponent.vue';

export default {
  components: { DeleteRoleComponent, TopSearch, EditRoleModalComponent, NewRoleModalComponent },
  name: "RolesTableComponent",
  data() {
    return {
      tableHeaders: [
        { name: "roleTitle", label: "Role Title" },
        { name: "permissions", label: "Permissions" },
        { name: "description", label: "Description" },
        { name: "actions", label: "Actions" },
      ],
    };
  },
  props: {
    roles: {
      type: Array,
      required: true
    }
  },
  methods: {
    getPermissionColor(permission) {
      switch (permission.toLowerCase()) {
        case "manageusers": return "#ff4c4c";
        case "manageroles": return "#28a745";
        case "managefilters": return "#007bff";
        case "managesamples": return "#ffc107";
        case "importsamples": return "#17a2b8";
        case "exportsamplestopvtintelligence": return "#6f42c1";
        case "guest": return "#6c757d";
        case "viewusers": return "#fd7e14";
        default: return "#6c757d";
      }
    },
    showModal(role) {
      this.$refs.deleteRoleModal.openModal(role); 
    },
    showEditModal(role) {
      this.$refs.editRoleModal.openModal(role); // Pass the role data to the modal
    },
    updateRole(updatedRole) {
      const roleIndex = this.roles.findIndex(role => role.id === updatedRole.id);
      if (roleIndex !== -1) {
        this.roles.splice(roleIndex, 1, updatedRole); // Update the role in the list
      }
    },
    deleteRole(roleId) {
      const roleIndex = this.roles.findIndex(role => role.id === roleId);
      if (roleIndex !== -1) {
        this.roles.splice(roleIndex, 1); // Remove the role from the list
      }
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
