<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#EditRole"
      ref="openModalEditRole"
    ></button>
    <div class="modal" tabindex="-1" role="dialog" id="EditRole">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body pt-2">
            <div class="edit-sec">
              <h1>Edit Role</h1>
              <div class="input-sec">
                <label for="role-title">Role Title</label>
                <input
                  v-model="role_title"
                  type="text"
                  class="form-control"
                  placeholder="Enter Role title"
                />
              </div>
              <div class="mt-3">
                <label for="permissions">Permissions</label>
                <vue3-select
                  v-model="select_permission"
                  :options="permissions"
                  multiple
                  placeholder="Select Permission(s)"
                />
              </div>
              <div class="form-check">
                <input
                  class="form-check-input custom-checkbox"
                  type="checkbox"
                  name="exampleRadios"
                  id="exampleRadios1"
                  v-model="suspendUser"
                />
                <label class="form-check-label" for="exampleRadios1">
                  Suspend User
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" class="btn btn-main" @click="updateRole">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditRoleModalComponent",
  data() {
    return {
      role_id: null, // to store the role ID
      role_title: '',
      role_description: '', // Add a variable for description if needed
      select_permission: [],
      permissions: [
        "Manage Users",
        "Manage Roles",
        "Manage Filter",
        "Manage Samples",
        "Import Samples",
        "Export Samples to PVT Intelligence",
      ],
      suspendUser: false
    };
  },
  methods: {
    openModal(role) {
      this.role_id = role.id; // Assuming role has an ID
      this.role_title = role.name; // Role name
      this.role_description = role.description || ''; // Assign existing description to the modal
      this.select_permission = role.permissions; // Assign existing permissions to the modal
      this.suspendUser = role.suspendUser || false; // Example: suspend user flag
      var elem = this.$refs.openModalEditRole;
      if (elem) {
        elem.click();
      }
    },
    camelCasePermission(permission) {
      return permission
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
          index === 0 ? word.toLowerCase() : word.toUpperCase()
        )
        .replace(/\s+/g, '');
    },
    async updateRole() {
      // Convert permissions to camelCase
      const formattedPermissions = this.select_permission.map((permission) =>
        this.camelCasePermission(permission)
      );

      const payload = {
        Data: {
          Role: {
            name: this.role_title,
            description: this.role_description || '', // Include description in the payload
            permissions: formattedPermissions,
          }
        }
      };

      try {
        const response = await fetch(`https://api.example.com/roles/${this.role_id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const result = await response.json();
          // Handle success (e.g., show a notification or refresh the list)
          alert("Role updated successfully!");
        } else {
          // Handle errors (e.g., show an error message)
          alert("Failed to update role.");
        }
      } catch (error) {
        console.error("Error updating role:", error);
      }
    },
  },
};
</script>
