<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#ReservoirChart"
      ref="openModalReservoirExpand"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="ReservoirChart">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header flex-row">
            <h5 class="modal-title">Reservoir</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <div :ref="chartId"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  name: "ReservoirExpandComponent",
  props: {
    reservoirData: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      chartId: "reservoirPieChartModal"
    };
  },
  methods: {
    openModal() {
      var elem = this.$refs.openModalReservoirExpand;
      if (elem) {
        elem.click();
      }
      this.$nextTick(() => {
        this.drawChart(); // Draw the chart when modal opens
      });
    },
    drawChart() {
      const values = this.reservoirData.map(item => item.totalCorrected);
      const labels = this.reservoirData.map(item => item.reservoir);

      const data = [
        {
          values: values,
          labels: labels,
          type: "pie",
          hole: 0.7,
          textinfo: "none",
          marker: {
            colors: ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0", "#9966ff"],
          },
        },
      ];

      const layout = {
        annotations: [
          {
            font: { size: 24 },
            showarrow: false,
            text: `<span style="font-size: 8px;">Total Records</span><br>${this.total}`,
            x: 0.5,
            y: 0.5,
          },
        ],
        showlegend: true,
        legend: {
          orientation: "v",
          y: 0.5,
        },
        height: 400, // Adjust height for modal
        margin: { t: 0, b: 0, l: 0, r: 0 },
      };

      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
      };

      Plotly.newPlot(this.$refs[this.chartId], data, layout, config);
    },
  },
};
</script>
