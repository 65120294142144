<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#EditUser"
      ref="openModalEditUser"
    ></button>
    <div class="modal" tabindex="-1" role="dialog" id="EditUser">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body pt-2">
            <div class="edit-sec">
              <h1>Edit User</h1>
              <div class="input-sec">
                <label for="email">Email Address:</label>
                <input
                  type="email"
                  v-model="email"
                  placeholder="Enter Email address"
                />
                <p v-if="errors.email" class="error-message">{{ errors.email }}</p>
              </div>
              <div class="input-sec">
                <label for="username">User name:</label>
                <input
                  type="text"
                  v-model="username"
                  placeholder="Enter User name here"
                />
                <p v-if="errors.username" class="error-message">{{ errors.username }}</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" class="btn btn-main" @click="updateUser">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "EditUserModalComponent",
  props: {
    user: Object
  },
  data() {
    return {
      email: null,
      username: null,
      errors: {
        email: null,
        username: null
      }
    };
  },
  watch: {
    user: {
      immediate: true, // Trigger when the component is mounted and when `user` changes
      handler(newUser) {
        if (newUser) {
          this.email = newUser.username; // Assuming email is username in your case
          this.username = newUser.firstName + " " + newUser.lastName; // Combine first and last names
        }
      }
    }
  },
  methods: {
    openModal() {
      const elem = this.$refs.openModalEditUser;
      if (elem) {
        elem.click();
      }
    },
    validateForm() {
      this.errors.email = null;
      this.errors.username = null;
      let isValid = true;

      // Validate email
      if (!this.email) {
        this.errors.email = "Email is required.";
        isValid = false;
      } else if (!this.validEmail(this.email)) {
        this.errors.email = "Please enter a valid email address.";
        isValid = false;
      }

      // Validate username
      if (!this.username) {
        this.errors.username = "Username is required.";
        isValid = false;
      }

      return isValid;
    },
    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    async updateUser() {
      if (!this.validateForm()) {
        return; // Stop if the form is invalid
      }

      try {
        const response = await axios.put(`/users/${this.user.id}`, {
          email: this.email,
          username: this.username
        });

        console.log("User updated successfully", response.data);

        // Show success message or close modal after successful update
        this.$emit('userUpdated', response.data); // Emit event to parent

        // Optionally close modal here
        $('#EditUser').modal('hide');
      } catch (error) {
        console.error("Error updating user:", error.response?.data || error.message);
        // Handle error (e.g., show a notification or error message)
        alert("Failed to update user.");
      }
    }
  }
};
</script>
