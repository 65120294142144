<template>
  <div class="dashboard-right-bottom-charts mt-3">
    <h6>2D Histograms</h6>
    <div class="row no-gutters mt-2">
      <div class="col-12 col-md-6">
        <label>Select X Variable</label>
        <vue3-select
          v-model="x_variable"
          :options="xOptions"
          :close-on-select="true"
          placeholder="Select x variable"
          @update:modelValue="fetchHistogramData"
        />
      </div>
      <div class="col-12 col-md-6 pl-md-3">
        <label>Select Y Variable</label>
        <vue3-select
          v-model="y_variable"
          :options="yOptions"
          :close-on-select="true"
          placeholder="Select y variable"
          @update:modelValue="fetchHistogramData"
        />
      </div>
      <div class="col-12 col-md-6">
        <label>Select X Variable</label>
        <vue3-select
          v-model="x_variable"
          :options="xOptions"
          :close-on-select="true"
          placeholder="Select x variable"
          @update:modelValue="fetchHistogramData"
        />
      </div>
      <div class="col-12 col-md-6 pl-md-3">
        <label>Select Y Variable</label>
        <vue3-select
          v-model="y_variable"
          :options="yOptions"
          :close-on-select="true"
          placeholder="Select y variable"
          @update:modelValue="fetchHistogramData"
        />
      </div>
    </div>
    <div class="container-fluid mt-3">
      <free-style-shimmer
        v-if="loading"
        :is-loading="true"
        height="300px"
        width="100%"
        border-radius="12px"
        color="#F6F6F6"
      />
      
      <div v-if="!loading && !histogramData" class="text-center mt-3">
        <p>No chart available.</p>
      </div>
      
      <div v-if="!loading && histogramData">
        <div class="row no-gutters">
          <div class="col-12 col-xl-6 pr-xl-1">
            <div class="dashboard-histogram-chart">
              <TwoDHistogram
                :xVariable="x_variable"
                :yVariable="y_variable"
                :chartData="histogramData"
              />
            </div>
          </div>
          <div class="col-12 col-xl-6 pl-xl-1">
            <div class="dashboard-histogram-chart">
              <TwoDHistogram
                :xVariable="x_variable"
                :yVariable="y_variable"
                :chartData="histogramData"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwoDHistogram from "@/components/Charts/2DHistogram.vue";
import axios from "axios"; 

export default {
  components: { TwoDHistogram },
  data() {
    return {
      x_variable: "C36+ Density", 
      y_variable: "C36+ Density", 
      xOptions: ["C36+ Density", "C36+ MW", "C36+ wt%"],
      yOptions: ["C36+ Density", "C36+ MW", "C36+ wt%"],
      histogramData: null,
      loading: false, 
    };
  },
  methods: {
    async fetchHistogramData() {
      if (!this.x_variable || !this.y_variable) {
        return; 
      }
      this.loading = true;
      try {
        const response = await axios.get(`/api/histogram`, {
          params: {
            x_variable: this.x_variable,
            y_variable: this.y_variable,
          },
        });
        this.histogramData = response.data; 
      } catch (error) {
        console.error("Error fetching histogram data", error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    // this.fetchHistogramData();
  },
};
</script>