<template>
  <div>
    <div class="table-container">
      <table class="table">
        <thead>
          <tr class="header-row">
            <th colspan="1">Component</th>
            <th colspan="1">MW</th>
            <th colspan="1">Density</th>
            <th colspan="2">Flashed Gas</th>
            <th colspan="2">Flashed Liquid</th>
            <th colspan="2">Overall</th>
          </tr>
          <tr class="units-row">
            <th></th>
            <th></th>
            <th></th>
            <th>mol%</th>
            <th>wt%</th>
            <th>mol%</th>
            <th>wt%</th>
            <th>mol%</th>
            <th>wt%</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in tableData"
            :key="index"
            :class="{ 'even-row': index % 2 === 1 }"
          >
            <td>{{ item.component }}</td>
            <td>{{ toFixedValueOne(item.mw) }}</td>
            <td>{{ toFixedValuethree(item.density) }}</td>
            <td>{{ toFixedValuethree(item.stockTankGas_MoleFraction) }}</td>
            <td>{{ toFixedValuethree(item.stockTankGas_MassFraction) }}</td>
            <td>{{ toFixedValuethree(item.stockTankOil_MoleFraction) }}</td>
            <td>{{ toFixedValuethree(item.stockTankOil_MassFraction) }}</td>
            <td>{{ toFixedValuethree(item.reservoirLiquid_MoleFraction) }}</td>
            <td>{{ toFixedValuethree(item.reservoirLiquid_MassFraction) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: "TableDataComponent",
  props: {
    sampleData: Object,
    useCorrectedData:Boolean
  },
  data() {
    return {
     
    };
  },
  computed: {
    tableData() {
      if (this.useCorrectedData) {
        return this.sampleData?.correctedComposition?.composition || [];
      } else {
        return this.sampleData?.originalComposition?.composition || [];
      }
    }
  },
  methods: {
    toFixedValueOne(value) {
      return value ? parseFloat(value).toFixed(1) : value;
    },
    toFixedValuetwo(value) {
      return value ? parseFloat(value).toFixed(2) : value;
    },
    toFixedValuethree(value) {
      return value ? parseFloat(value).toFixed(3) : value;
    },
    toFixedValueFour(value) {
      return value ? parseFloat(value).toFixed(4) : value;
    },
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
