import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import axios from 'axios';
import SampleSummary from '../pages/SampleSummary.vue';
import ViewSummary from '../pages/ViewSummary.vue';
import AICorrection from "../pages/AICorrection.vue";
import NotFoundPage from '../pages/NotFoundPage.vue'; 
import ErrorPage from '../pages/ErrorPage.vue';
import Login from "../pages/Login.vue";
import SystemManagement from "../pages/SystemManagement.vue"
import Dashboard from "../pages/Dashboard.vue"

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  // {
  //   path: '/',
  //   name: 'SampleSummary',
  //   component: SampleSummary,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/summary/view/:id',
    name: 'ViewSummary',
    component: ViewSummary,
    meta: { requiresAuth: true }
  },
  {
    path: '/sample/import/ai-correction/:id?',
    name: 'AICorrection',
    component: AICorrection,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'SampleSummary',
    component: SampleSummary,
    meta: { requiresAuth: true }
  },
  {
    path: '/manage-users',
    name: 'SystemManagment',
    component: SystemManagement
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/404',
    name: 'NotFoundPage',
    component: NotFoundPage
  },
  {
    path: '/error',
    name: 'ErrorPage',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // Function to get query parameters and set token
  const getQueryParams = () => {
    const query = to.query;
    const token = query.token;

    if (token) {
      const userData = {
        expires: query.expires,
        firstName: query.firstName,
        lastName: query.lastName,
        role: query.role,
        token: token,
        username: query.username
      };
      store.commit('setToken', token);
      store.commit('setUser', userData);
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  };

  // Call tryAutoLogin action
  store.dispatch('tryAutoLogin').then(() => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // Check if the token is in the query parameters
      getQueryParams();

      if (!store.getters.isAuthenticated) {
        next('/login');
      } else {
        // Remove the query parameters from the URL
        if (Object.keys(to.query).length) {
          const newUrl = {
            ...to,
            query: {}
          };
          router.replace(newUrl);
        }
        next();
      }
    } else {
      next();
    }
  });
});



export default router;
