<template>
  <div>
    <ChartExpandComponent ref="expandChart" :filterData="filterData" />
    <div class="top-content">
      <h6>Data Clusters</h6>
      <!-- <IconComponent /> -->
    </div>
    <ChartDataComponent :filterData="filterData"/>
    <button type="button" class="btn expand-btn" @click="showModal">
      <img :src="expand" alt="expand" />
      <span>Expand</span>
    </button>
  </div>
</template>

<script>

// import IconComponent from "./IconComponent.vue";
import ChartDataComponent from "./ChartDataComponent.vue";
import ChartExpandComponent from './ChartExpandComponent.vue';

export default {
  name: 'ChartComponent',
  components: {
    // IconComponent,
    ChartDataComponent,
    ChartExpandComponent
  },
  data() {
    return {
      expand: require("../../assets/images/table/expand.png"),
    }
  },
  props: {
    filterData: Object,
  },
  methods: {
     showModal() {
      this.$refs.expandChart.openModal();
    },
  }
}
</script>
