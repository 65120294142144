<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#correctionModal"
      ref="openModalCorrection"
    ></button>

    <div
      class="modal fade p-0"
      id="correctionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-body mr-0 align-items-center">
            <h6>{{ currentHeading }}</h6>
            <step-progress
              :steps="mySteps"
              :current-step="currentStep"
              :line-thickness="2"
              icon-class="fa fa-check"
            ></step-progress>
            <div class="sample-chart-wrap">
              <div class="samples-list">
                <h6>Samples</h6>
                <div
                  v-for="sample in samples"
                  :key="sample.id"
                  class="sample-info"
                >
              <!-- <div
                  class="sample-info"
                > -->
                  <p class="id" v-if="sample.correctionStatus !== false">Sample ID#{{ sample?.id }}</p>
                  <p v-if="sample.correctionStatus !== false">
                    <span>Asset</span>
                    <span class="no">{{ sample?.location?.asset }} | </span>
                    <span>Field</span>
                    <span class="no">{{ sample?.location?.field }} | </span>
                    <span>Well</span>
                    <span class="no">{{ sample?.location?.well }} | </span>
                    <span>Sample</span>
                    <span class="no">{{ sample?.name }} </span>
                  </p>
                </div>
              </div>
              <div class="right-section" :class="{ 'step-4': currentStep === 4 }" v-if="currentStepText">
                <div v-if="currentStep === 4" class="table-container">
                  <!-- Display table if current step is 4 -->
                  <div v-html="currentStepText.tableHtml"></div>
                </div>
                <div v-else class="img">
                  <img :src="currentStepText.image" />
                </div>
                <div>
                  <span class="circle custom-circle">{{ currentStep }}</span>
                  <h6 v-html="currentStepText.heading"></h6>
                  <p v-html="currentStepText.text"></p>
                </div>
              </div>
            </div>
            <div class="progress w-75">
              <div
                class="progress-bar"
                role="progressbar"
                :aria-valuenow="formattedProgressValue"
                aria-valuemin="0"
                aria-valuemax="100"
                :style="{ width: formattedProgressValue + '%' }"
              >
                {{ formattedProgressValue }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepProgress from "vue-step-progress";
import "vue-step-progress/dist/main.css";
import $ from "jquery";

export default {
  name: "CorrectionComponent",
  components: {
    "step-progress": StepProgress,
  },
  data() {
    return {
      sampleId:'',
      mySteps: ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"],
      currentStep: 1,
      stepHeadings: [
        "QC the fluid Input on samples",
        "Validating AI model on samples",
        "Locate fluid in anchor points on samples",
        "Estimate distances from all anchor points on samples",
        "Estimate corrected values on samples",
        "Calculate corrected reservoir fluid composition on samples",
      ],
      stepTexts: [
        {
          heading: "Qualify fluid for Composition Correction",
          text: "Qualify/Reject the uncorrected STL composition input for Compo++ AI-ML correction​",
          image: require("@/assets/images/AiCorrection/step-two.png"),
        },
        {
          heading: "Locate Fluid in the Existing Multidimension Space",
          text: "Qualify/Reject the uncorrected STL composition input for Compo++ AI-ML correction.",
          image: require("@/assets/images/AiCorrection/step-three.png"),
        },
        {
          heading: "Calculate AI-ML Model Parameters and Similarity Index",
          text: "Calculate weighted distances and AI-ML models parameters for C36+’s wt%, SG & MW correction.",
          image: require("@/assets/images/AiCorrection/ai-4.png"),
        },
        {
          heading: "Correct Plus Fraction Properties with AI-ML Models",
          text: "Calculate weighted distances and AI-ML models parameters for C36+’s wt%, SG & MW correction.",
          tableHtml: `
            <table class="table">
              <thead>
                <tr class="header-row">
                  <th>Parameter Correction</th>
                  <th>Uncorrected</th>
                  <th>Corrected</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>STL C36+, wt%</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>STL C36+ Density, g/cc</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>STL C36+MW, g/mol</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          `,
        },
        {
          heading: "Correct STL Compositions with AI-ML Models",
          text: "Calculate weighted distances and AI-ML models parameters for C36+’s wt%, SG & MW correction.",
          image: require("@/assets/images/AiCorrection/ai-5.png"),
        },
        {
          heading: "Correct Live Compositions with AI-ML Models",
          text: "Calculate and report AI-ML corrected plus fraction properties and compositions for live oil.",
          image: require("@/assets/images/AiCorrection/ai-6.png"),
        },
      ],
      samples: [],
    };
  },
  computed: {
    currentHeading() {
      return this.stepHeadings[this.currentStep - 1] || ""; // Adjust index for headings
    },
    progressValue() {
      return (this.currentStep / this.mySteps.length) * 100; // Adjust index for progressValues
    },
    formattedProgressValue() {
      return Math.round(this.progressValue);
    },
    currentStepText() {
      return this.stepTexts[this.currentStep - 1] || ""; // Adjust index for stepTexts
    },
  },
  methods: {
    openModal(sampleData,sampleId) {
      this.sampleId=sampleId;
      this.samples=sampleData;
      this.currentStep = 1;
      var elem = this.$refs.openModalCorrection;
      if (elem) {
        elem.click();
        this.startCounter();
      }
    },
    startCounter() {
        this.autoStep = setInterval(() => {
        if (this.currentStep < this.mySteps.length) {
          this.nextStep();
        } else {
          clearInterval(this.autoStep);

          // Manually hide the modal
          const modalElement = document.getElementById("correctionModal");
          if (modalElement) {
            $(modalElement).modal("hide"); // Use jQuery to hide the modal
          }

          // Delay the redirect slightly to ensure the modal has time to close
          setTimeout(() => {
            if(this.sampleId)
            {
              this.$router.push(`/summary/view/${this.sampleId}`);
            }
            else
            {
              this.$router.push("/");
            }
          });
        }
      }, 5000);
    },
    nextStep() {
      if (this.currentStep < this.mySteps.length) {
        this.currentStep++;
      } else {
        this.redirectToHome();
      }
    },
    redirectToHome() {
      this.$router.push("/");
    },
  },
  beforeDestroy() {
    clearInterval(this.autoAdvanceInterval);
  },
  mounted() {
    
  },
};
</script>

<style>
.progress-bar {
  transition: width 0.5s ease-in-out;
}

.right-section.step-4 {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start !important;
  height: 100%;
  justify-content: space-between;
}
</style>
