<template>
  <div class="dashboard-top-left">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 top-head-btn">
          <div class="row no-gutters">
            <h6>Dashboard</h6>
            <button type="button" class="btn reset-btn main-btn">
              <img :src="reset" alt="reset_data" />
              <span>Reset</span>
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4 pr-0 pr-lg-2">
          <AssetComponent
            :assetData="assetData"
            :totalItems="pagination.totalItems"
          />
        </div>
        <div class="col-12 col-lg-6 col-xl-4 pr-0 pr-xl-2">
          <FieldComponent
            :fieldData="fieldData"
            :totalItems="pagination.totalItems"
          />
        </div>
        <div class="col-12 col-lg-6 col-xl-4 pr-0 pr-lg-2 pr-xl-0">
          <ReserviorComponent
            :reservoirData="reservoirData"
            :totalItems="pagination.totalItems"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AssetComponent from "@/components/Common/AssetComponent.vue";
import FieldComponent from "@/components/Common/FieldComponent.vue";
import ReserviorComponent from '@/components/Common/ReservoirComponent.vue';

export default {
  name: "DashboardLeftTopComponent",
  components: {
    AssetComponent,
    FieldComponent,
    ReserviorComponent,
  },
  data() {
    return {
      assetData: [],
      fieldData: [],
      reservoirData:[],
      filterData: {
        asset: [],
        field: [],
        reservoir: [],
        well: [],
        sample: [],
        sampleType: [],
      },
      pagination: {
        totalItems: 0,
      },
      reset: require("../../../assets/images/modal/refresh-2.png"),
    };
  },
  methods: {
    async applyFilterFromPieChart(filterData) {
      this.loading = true;
      this.filterData = filterData;
      await this.fetchData(1);
    },
    fetchMetrics() {
      axios
        .get("samples/metrics", {
          params: {
            asset:
              this.filterData.asset.length > 0
                ? JSON.stringify(this.filterData.asset)
                : "",
            field:
              this.filterData.field.length > 0
                ? JSON.stringify(this.filterData.field)
                : "",
            well:
              this.filterData.well.length > 0
                ? JSON.stringify(this.filterData.well)
                : "",
            reservoir:
              this.filterData.reservoir.length > 0
                ? JSON.stringify(this.filterData.reservoir)
                : "",
            sampleType:
              this.filterData.sampleType.length > 0
                ? JSON.stringify(this.filterData.sampleType)
                : "",
          },
        })
        .then((response) => {
          const data = response.data.data;
          this.assetData = data.filter((item) => item.scope === "asset");
          this.fieldData = data.filter((item) => item.scope === "field");
          this.reservoirData = data.filter((item) => item.scope === "field");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    async fetchData(page = 1) {
      this.error = false;
      this.errorMessage = "";
      try {
        const response = await axios.get(`samples/summary`, {
          params: {
            pageNumber: page,
            pageSize: this.pagination.itemsPerPage,
            asset:
              this.filterData.asset.length > 0
                ? JSON.stringify(this.filterData.asset)
                : "",
            field:
              this.filterData.field.length > 0
                ? JSON.stringify(this.filterData.field)
                : "",
            well:
              this.filterData.well.length > 0
                ? JSON.stringify(this.filterData.well)
                : "",
            reservoir:
              this.filterData.reservoir.length > 0
                ? JSON.stringify(this.filterData.reservoir)
                : "",
            sampleType:
              this.filterData.sampleType.length > 0
                ? JSON.stringify(this.filterData.sampleType)
                : "",
          },
        });
        const data = response.data;
        this.pagination.totalItems = data.pagination.totalRecords;
      } catch (error) {
        this.error = true;
        this.errorMessage = "Error fetching data";
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchData()
    this.fetchMetrics();
  },
};
</script>