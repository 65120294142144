<template>
  <div class="system-managment-sec">
    <ul class="nav nav-pills" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button"
          role="tab" aria-controls="pills-home" aria-selected="true">
          Manage User
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-profile-tab" data-toggle="pill" data-target="#pills-profile" type="button"
          role="tab" aria-controls="pills-profile" aria-selected="false">
          Manage Roles
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
        <AddUserComponent />
        <UserTableComponent :users="users" />
      </div>
      <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
        <AddRoleComponent />
        <RolesTableComponent :roles="roles" />
      </div>
    </div>
  </div>
</template>

<script>
import AddRoleComponent from './AddRoleComponent.vue';
import AddUserComponent from './AddUserComponent.vue';
import RolesTableComponent from './RolesTableComponent.vue';
import UserTableComponent from './UserTableComponent.vue';
import axios from 'axios';

export default {
  components: { AddUserComponent, AddRoleComponent, UserTableComponent, RolesTableComponent },
  name: "ManagementTabsComponent",
  data() {
    return {
      users: [],
      roles: [],
    };
  },
  methods: {
    // Fetch users
    async fetchUsers() {
      try {
        const response = await axios.get('/users');
        this.users = response.data.data;
        if (!Array.isArray(this.users)) {
          console.error("Users data is not an array:", this.users);
          this.users = [];
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },

    // Fetch roles
    async fetchRoles() {
      try {
        const response = await axios.get('/roles');
        this.roles = response.data.data;
        if (!Array.isArray(this.roles)) {
          console.error("Roles data is not an array:", this.roles);
          this.roles = [];
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    }
  },
  mounted() {
    // Fetch both users and roles on component mount
    this.fetchUsers();
    this.fetchRoles();
  },
};
</script>
