<template>
  <div>
    <FieldExpandComponent ref="fieldExpandChart" :fieldData="fieldData" :totalItems="totalItems" />
    <div class="chart-data-fields">
      <div class="top-content">
        <h6>Fields</h6>
        <!-- <IconComponent /> -->
      </div>
      <div :ref="chartId"></div>
      <button type="button" class="btn expand-btn" @click="showModal">
        <img :src="expand" alt="expand" />
        <span>Expand</span>
      </button>
    </div>
  </div>
</template>

<script>
// import IconComponent from "./IconComponent.vue";
import Plotly from "plotly.js-dist";
import FieldExpandComponent from './FieldExpandComponent.vue';

export default {
  components: { FieldExpandComponent },
  name: "FieldComponent",
  // components: { IconComponent },
  props: {
    fieldData: {
      type: Array,
      required: true
    },
    totalItems: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      chartId: "fieldPieChart",
      expand: require("../../assets/images/table/expand.png"),
      total:0,
      clickedLabel:'',
    };
  },
  watch: {
    fieldData: {
      handler(newData) {
        if (newData && newData.length > 0) {
          this.total = newData.reduce((acc, item) => acc + item.totalCorrected, 0);
          this.drawChart();
        }
      },
      immediate: true,
      deep: true
    },
    totalItems(newTotal) {
    this.total = newTotal;
    if (this.$refs[this.chartId] && this.$refs[this.chartId].data) {
      if (this.clickedLabel !== '') {
          Plotly.relayout(this.$refs[this.chartId], {
            'annotations[0].text': `<span style="font-size: 8px;">Total Records (${this.clickedLabel})</span><br>${this.total}`,
          });
        } else {
          Plotly.relayout(this.$refs[this.chartId], {
            'annotations[0].text': `<span style="font-size: 8px;">Total Records</span><br>${this.total}`,
          });
        }
        this.clickedLabel='';
      }
    }
  },
  methods: {
     showModal() {
      this.$refs.fieldExpandChart.openModal();
    },
    drawChart() {
      const values = this.fieldData.map(item => item.totalCorrected);
      const labels = this.fieldData.map(item => item.field);

      const data = [
        {
          values: values,
          labels: labels,
          type: "pie",
          hole: 0.7,
          textinfo: "none",
          marker: {
            colors: ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0", "#9966ff"],
          },
        },
      ];

      const layout = {
        annotations: [
          {
            font: { size: 24 },
            showarrow: false,
            text: `<span style="font-size: 8px;">Total Records</span><br>${this.totalItems}`,
            x: 0.5,
            y: 0.5,
          },
        ],
        showlegend: true,
        legend: {
          orientation: "v",
          y: 0
        },
        height: 280,
        margin: { t: 0, b: 0, l: 0, r: 0 },
      };

      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
      };

      Plotly.newPlot(this.$refs[this.chartId], data, layout, config);
      this.$refs[this.chartId].on('plotly_click', (data) => {
        const clickedValue = data.points[0].value;
        const clickedLabel = data.points[0].label;
        this.clickedLabel=clickedLabel;
        this.handleClickOnSlice(clickedLabel, clickedValue);
      });
      // this.updateTotalOnLegendClick();
    },
    handleClickOnSlice(label, value) {
      // This function will be called when a slice is clicked
      const filterData= {
            asset: [],
            field: label.split(","),
            reservoir:[],
            well: [],
            sample:[],
            sampleType:[],
            isCorrected:null,
            filterType:null,
        };
        this.$emit("apply-filter", filterData);
    },
    updateTotalOnLegendClick() {
      this.$refs[this.chartId].on('plotly_restyle', (eventData) => {
        const updatedVisibility = eventData[0]['visible'];

        // Recalculate the total based on the visibility of each slice
        let updatedTotal = 0;
        updatedVisibility.forEach((isVisible, index) => {
          if (isVisible || isVisible === true) {
            updatedTotal += this.fieldData[index].totalCorrected;
          }
        });

        this.total = updatedTotal;

        // Update the annotation with the new total
        Plotly.relayout(this.$refs[this.chartId], {
          'annotations[0].text': `<span style="font-size: 8px;">Total Records</span><br>${this.total}`,
        });
      });
    },
  },
};
</script>
