<template>
  <div class="dashboard-inventory-analysis">
    <h6>Inventory Analysis</h6>
    <free-style-shimmer
      v-if="loading"
      :is-loading="true"
      height="300px"
      width="100%"
      border-radius="12px"
      color="#F6F6F6"
    />

    <BarChartComponent v-if="!loading" :chart-data="chartData" />
  </div>
</template>

<script>
import axios from "axios"; 
import BarChartComponent from "@/components/Common/BarChartComponent.vue";
export default {
  name: "DashboardInventoryAnalysisComponent",
  components: { BarChartComponent },
  data() {
    return {
      loading: false, 
      chartData: null,
    };
  },
  methods: {
    fetchChartData() {
      axios.get("/api/chart-data")
        .then((response) => {
          this.chartData = response.data; 
          this.loading = false; 
        })
        .catch((error) => {
          console.error("Error fetching chart data:", error);
          this.loading = false; 
        });
    },
  },
  mounted() {
    // this.fetchChartData(); 
  },
};
</script>